<template>
    <div class="flex-fill d-flex flex-column w-100">
        <div class="p-2 d-flex flex-column flex-fill" v-if="detailModel">
            <div class="d-flex align-items-stretch animate__animated  animate__bounceInDown">
                <div class="mr-2 card p-2 d-flex align-items-center">
                    <div class="d-flex align-items-center mt-0">
                        <div class="mr-1">
                            <v-avatar size="4rem">
                                <v-img id="app-avatar" src="/img/avatar5.png" />
                            </v-avatar>
                        </div>
                        <div class="flex-fill">
                            <h5 class="mb-0 text-primary">

                                <button type="button"
                                        class="p-0 pl-1 pr-1 ml-1"
                                        data-toggle="tooltip"
                                        title="Click copy to clipboard"
                                        @click="copyToClipboard('#infoInputCopy', `${detailModel.FullName}`)">
                                    <strong>{{detailModel.FullName}}</strong>
                                </button>
                            </h5>
                            <div>
                                <button type="button"
                                        class="p-0 pl-1 pr-1 ml-1"
                                        data-toggle="tooltip"
                                        title="Click copy to clipboard"
                                        @click="processCopyEmail(detailModel.staffCare, detailModel.Email, '#infoInputCopy')">
                                    <span class="text-muted">
                                        {{ processEmailUser(detailModel.staffCare, detailModel.Email)}}
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button type="button"
                                        class="p-0 pl-1 pr-1 ml-1"
                                        data-toggle="tooltip"
                                        title="Click copy to clipboard"
                                        @click="processCopyEmail(detailModel.staffCare, detailModel.Phone, '#infoInputCopy')">
                                    <span class="text-muted">
                                        {{ processUserPhone(detailModel.staffCare, detailModel.Phone)}}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mr-2 card p-2 d-flex align-items-center">
                    <div>
                        <el-tooltip class="item"
                                    effect="light"
                                    :content="`User level ${detailModel.userLevel}`"
                                    placement="left">
                            <div :style="`filter: ${detailModel.totaL_RECHARGE_BAL2 === 0 ? 'grayscale(100%); opacity: 0.5' : 'none'}`"
                                 class="d-flex flex-row align-items-center">
                                <div class="d-flex align-items-center" style="width: 1.25rem;">
                                    <i :style="`color: #FFA726; font-size: ${detailModel.userLevel > 0 ? '1rem' : '0.83rem'};`"
                                       :class="`${detailModel.userLevel > 0 ?'el-icon-star-on' : 'el-icon-star-off'}`"></i>
                                </div>
                                <div class="d-flex align-items-center" style="width: 1.25rem;">
                                    <i :style="`color: #FA8B34; font-size: ${detailModel.userLevel > 1 ? '1rem' : '0.83rem'};`"
                                       :class="`${detailModel.userLevel > 1 ?'el-icon-star-on' : 'el-icon-star-off'}`"></i>
                                </div>
                                <div class="d-flex align-items-center" style="width: 1.25rem;">
                                    <i :style="`color: #F46F42; font-size: ${detailModel.userLevel > 2 ? '1rem' : '0.83rem'};`"
                                       :class="`${detailModel.userLevel > 2 ?'el-icon-star-on' : 'el-icon-star-off'}`"></i>
                                </div>
                                <div class="d-flex align-items-center" style="width: 1.25rem;">
                                    <i :style="`color: #EF5350; font-size: ${detailModel.userLevel > 3 ? '1rem' : '0.83rem'};`"
                                       :class="`${detailModel.userLevel > 3 ?'el-icon-star-on' : 'el-icon-star-off'}`"></i>
                                </div>
                            </div>
                        </el-tooltip>
                        <el-tooltip style="width: 3.5rem; margin-left: 0.05rem;"
                                    class="item"
                                    effect="light"
                                    :content="`Total deposit:\xa0\xa0\xa0${detailModel.totalRecharge} $`"
                                    placement="bottom">
                            <div :style="`filter: ${detailModel.totalRecharge === 0 ? 'grayscale(100%); opacity: 0.5;' : 'none'}`"
                                 class="d-flex flex-row align-items-center">
                                <i class="el-icon-money"></i>
                                <div class="ml-2" style="font-weight: 600;">
                                    <span style="color: #388E3C;">{{detailModel.totalRecharge}}</span>
                                    <span class="ml-1" style="color: #388E3C;">$</span>
                                </div>
                            </div>
                        </el-tooltip>
                        <el-tooltip style="margin-left: 0.05rem;"
                                    class="item"
                                    effect="light"
                                    :content="`Balance:\xa0\xa0\xa0${detailModel.balance} ${$pointNameShort}`"
                                    placement="bottom">
                            <div :style="`filter: ${detailModel.balance === 0 ? 'grayscale(100%); opacity: 0.5;' : 'none'}`"
                                 class="d-flex flex-row align-items-center">
                                <i class="el-icon-coin"></i>
                                <div class="ml-2" style="font-weight: 600;">
                                    <span style="color: #007bff;">{{detailModel.balance}}</span>
                                    <span class="ml-1" style="color: #007bff;">{{$pointNameShort}}</span>
                                </div>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div class="mr-2 card p-2 d-flex align-items-center">
                    <div class="d-flex flex-row">
                        <div class="d-flex mt-1">
                            <img style="height: 2rem;" :src="`/flag/${detailModel.region}.svg`" />
                        </div>
                        <div class="ml-2 text-nowrap">
                            <div style="line-height: 1.3rem; font-weight: 600;">
                                <span>{{$countryList[detailModel.region]}}</span>
                                <span class="ml-1" style="font-weight: 500;">({{detailModel.region}})</span>
                            </div>
                            <div style="line-height: 1.3rem;">{{detailModel.userCreatedAt | dateFull}}</div>
                        </div>
                    </div>
                </div>
                <div class="mr-2 card p-2 d-flex align-items-center">
                    <div>
                        <el-tooltip class="item text-center"
                                    v-if="detailModel.PackageId !== null"
                                    effect="light"
                                    :content="`${getPackageInfo(detailModel.PackageId).name}\xa0\xa0\xa0(\xa0${getPackageInfo(detailModel.PackageId).description}\xa0)`"
                                    placement="bottom">
                            <div>
                                <div v-if="getPackageInfo(detailModel.PackageId).serviceType !== 3">
                                    <span style="color: darkorange; font-size: 1.5rem; font-weight: 600;">{{getPackageInfo(detailModel.PackageId).numberCard}}</span>
                                    <span style="font-size: 1.25rem; font-weight: 600;" class="ml-1">CARD</span>
                                </div>
                                <div v-else
                                     class="d-flex flex-column align-items-center justify-content-center pb-1"
                                     style="font-size: 0.7rem;">
                                    <div style="line-height: 1; font-weight: 500; color: darkorange; font-size: 0.8rem;">
                                        <strong>Dual Xeon E5</strong>
                                    </div>
                                    <div class="mb-1"
                                         style="line-height: 1; font-style: italic; color: #9E9E9E; font-size: 0.65rem;">(20 cores 40 threads)</div>
                                </div>
                                <img class="mb-1"
                                     style="height: 2rem;"
                                     :src="`/package/${detailModel.PackageId}.png`" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div class="mr-2 card p-2 d-flex align-items-center">
                    <div class="d-flex flex-row">
                        <div>
                            <div class="mt-1">
                                <strong><i class="el-icon-monitor text-primary"></i> {{detailModel.NumberMachine}} Machines</strong>
                            </div>
                            <div class="mt-1">
                                <i class="el-icon-time text-primary"></i> {{detailModel.Period}} {{periodUnitCategories[detailModel.PeriodUnit]}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-fill"></div>
                <div class="mr-2 card p-0 d-flex flex-column align-items-center"
                     style="border-radius: 500px;">
                    <el-button size="small" class="flex-fill pl-4 pr-4 bg-gradient-danger"
                               type="danger" style="border-radius: 500px;">
                        <div><i class="el-icon-close fa-3x"></i></div>
                        <div class="mt-0"><span style="font-size: 1rem;">Ignore</span></div>
                    </el-button>
                </div>
            </div>
            <div class="animate__animated animate__bounceIn">
                <div class="alert alert-warning mt-2 p-1 pl-2 pr-2">
                    <div class="text-navy"><strong><i class="el-icon-chat-line-square"></i> Note</strong></div>
                    <div class="text-navy">{{detailModel.Note}}</div>
                </div>
            </div>
            <div class="mt-1 flex-fill d-flex flex-column animate__animated animate__fadeInUpBig">
                <div class="d-flex align-items-center pt-2 pb-2 pl-2" 
                     style="background-color: #f5f5f5; border-top-left-radius: 10px; border-top-right-radius: 10px;">
                    <div class="flex-fill" style="color: #01579B;">
                        <strong><i class="el-icon-position d-inline-block mr-2"></i>Detail of this request</strong>
                    </div>
                    <div class="mr-3">
                        <el-button class="bg-gradient-primary"
                                   size="small"
                                   type="primary"
                                   @click="refresh"
                                   round>
                            <i class="el-icon-refresh"></i>
                            <span class="ml-2">Refresh</span>
                        </el-button>
                    </div>
                </div>
                <VuePerfectScrollbar class="flex-fill mt-2 detail-list container-fluid d-flex flex-row" 
                                     style="max-height: 60vh;" v-loading="isLoadingList" 
                                     :settings="{ suppressScrollX : true }"> 
                    <ul style="list-style: none; list-style-position: inside;" class="row pr-0"
                        v-if="listData.length > 0">
                        <li :class="`p-1 mt-2 mb-2 col-12 col-md-12 mb-0 pt-0 pb-0  animate__animated animate__backInDown`" 
                            :key="detailItem.id" v-for="(detailItem) in listData">
                            <el-card class="p-0">
                                <div class="d-flex flex-row align-items-center">
                                    <div class="d-flex">
                                        <img :src="`/os/${detailItem.operatingSystem}.svg`" title="Operating System" style="width: 2.2rem;">
                                        <div class="ml-1">
                                            <div><span class="ml-2">Operating system</span></div>
                                            <div>
                                                <strong v-if="detailItem.operatingSystem && detailItem.operatingSystem !== null" class="ml-2">
                                                    {{detailItem.operatingSystem === 1 ? "Windows 10" : "Ubuntu 18.04"}}
                                                </strong>
                                                <strong v-else class="ml-2">
                                                    Unset
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="ml-2 pl-2" style="border-left: 2px solid #efefef !important;">
                                        <div>
                                            <i class="el-icon-switch-button text-success"></i> Boot after created
                                        </div>
                                        <div>                                            
                                            <strong v-if="detailItem.bootAfterCreated !== null">
                                                <span v-if="detailItem.bootAfterCreated">True</span>
                                                <span v-else>False</span>
                                            </strong>
                                            <strong v-else>
                                                Unset
                                            </strong>
                                        </div>
                                    </div> -->
                                    <!-- <div class="ml-2 pl-2" style="border-left: 2px solid #efefef !important;">
                                        <div>
                                            <i class="el-icon-monitor text-primary"></i> Machine ID
                                        </div>
                                        <div>
                                            <strong v-if="detailItem.machineId !== null && detailItem.machineId !== 0">
                                                <span>{{detailItem.machineId}}</span>
                                            </strong>
                                            <strong v-else>
                                                Unset
                                            </strong>
                                        </div>
                                    </div> -->
                                    <div class="ml-2 pl-2" style="border-left: 2px solid #efefef !important;">
                                        <div>
                                            <i class="el-icon-date text-primary"></i> Created at
                                        </div>
                                        <div>
                                            <strong>{{detailItem.createdAt | pretyDateUtc}}</strong>
                                        </div>
                                    </div>
                                    <div class="ml-2 flex-fill">
                                        <div class="pl-2 d-flex justify-content-end align-items-center">
                                            <div>
                                                <div v-if="detailItem.status === statusDictionary.Ok">
                                                    <el-tooltip class="item text-center"
                                                                effect="light"
                                                                content="Status: Ready"
                                                                placement="bottom">
                                                        <i class="el-icon-circle-check fa-2x text-success"></i>
                                                    </el-tooltip>
                                                </div>
                                                <div v-else>
                                                    <el-tooltip class="item text-center"
                                                                effect="light"
                                                                content="Status: Pending"
                                                                placement="bottom">
                                                        <i class="el-icon-time fa-2x text-danger"></i>
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                            <!--<div class="ml-3">
                                                <el-dropdown class="hover-primary">
                                                    <span class="el-dropdown-link">
                                                        <i class="el-icon-s-operation fa-2x text-muted"></i>
                                                    </span>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <el-dropdown-item style="line-height: normal;">
                                                            <div class="d-flex align-items-center">
                                                                <div class="mr-1">
                                                                    <i class="el-icon-edit text-primary fa-2x"></i>
                                                                </div>
                                                                <div>
                                                                    <div><strong class="text-navy">Edit quantity this request</strong></div>
                                                                    <div><small class="text-muted">Change quantity to make request ready</small></div>
                                                                </div>
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item divided style="line-height: normal;">
                                                            <div class="d-flex align-items-center">
                                                                <div class="mr-1">
                                                                    <i class="el-icon-lock text-primary fa-2x"></i>
                                                                </div>
                                                                <div>
                                                                    <div><strong class="text-navy">Lock this request</strong></div>
                                                                    <div><small class="text-muted">Others user cannot pick the server selected</small></div>
                                                                </div>
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item divided style="line-height: normal;">
                                                            <div class="d-flex align-items-center">
                                                                <div class="mr-1">
                                                                    <i class="el-icon-delete text-danger fa-2x"></i>
                                                                </div>
                                                                <div>
                                                                    <div><strong class="text-navy">Remove this request</strong></div>
                                                                    <div><small class="text-muted">Remove this to make request ready</small></div>
                                                                </div>
                                                            </div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </li>
                    </ul>
                    <div v-else
                        class="nodata-container flex-fill d-flex align-items-center justify-content-center h-100">
                        <div class="align-self-center text-center">
                            <div class="mb-5">
                                <img src="/static/image/no-job-icon.svg" style="opacity: 0.7;" />
                            </div>
                            <h4>
                                <strong class="text-muted">There is no detail request has been added</strong>
                            </h4>
                            <p class="text-muted">All detail request will be show here !</p>
                        </div>
                    </div>
                </VuePerfectScrollbar>
            </div>
        </div>
    </div>
</template>

<script>
    import commonAct from '@/commonActionHandle.js';
    import baseComponent from "@/scripts/baseComponent";
    import usersRequest from '@/api/common/usersRequest';
    import { mapState } from "vuex";
    import moment from "moment";
    import { periodUnitCategories } from '@/constant/config.js';
    const queryString = require('query-string');
    export default {
        extends: baseComponent,
        props: {
            detailModel: {
                type: Object,
                default: null,
            }
        },
        watch: {
            detailModel(newval) {
                console.error("detailModel", newval);
                this.getData(this.pagination);
            }
        },
        data() {
            return {    
                listData: {},
                pagination: {
                    queryString: '',
                    orderBy: 'CREATED_AT',
                    directionSort: 'ASC',
                    pageIndex: 1,
                    pageSize: 100,
                    outRowsNumber: 0,
                },
                isLoadingList: false,            
                packageInfoDictionary: {},
                statusDictionary: {
                    Unset: 0,
                    Ok : 1,
                    NotOk : 2
                },
                periodUnitCategories: periodUnitCategories
            };
        },
        created() {
            this.getData(this.pagination);
        },
        computed: {
            ...mapState({
                listStaff: state => state.staffs,
                listServicePack: (state) => state.common.listServicePack,
            }),
        },
        methods: {  
            getPackageInfo(elementServicePackage) {
                if (this.packageInfoDictionary.hasOwnProperty(elementServicePackage)) return this.packageInfoDictionary[elementServicePackage];
                let packageInfo = this.listServicePack.find(x => x.id === elementServicePackage.toLowerCase());
                if (packageInfo && packageInfo !== null && elementServicePackage && elementServicePackage !== null) {
                    this.packageInfoDictionary[elementServicePackage] = packageInfo;
                    return packageInfo;
                }
                else {
                    return {
                        name: "---",
                        description: "---",
                        serviceType: 0,
                        numberCard: "---"
                    }
                }
            },
            // Get data
            getData(request) {
                this.isLoadingList = true;
                usersRequest.getUserRequestDetail(this.detailModel.Id, queryString.stringify(request))
                    .then((res) => {
                        try {
                            let responseData = res.data;
                            let pagingItem = res.data.pagingItem;
                            this.listData = responseData.data;
                            this.pagination.orderBy = pagingItem.orderBy;
                            this.pagination.directionSort = pagingItem.directionSort;
                            this.pagination.pageIndex = pagingItem.pageIndex;
                            this.pagination.pageSize = pagingItem.pageSize;
                            this.pagination.outRowsNumber = pagingItem.outRowsNumber;
                        } catch (error) {
                            console.error("getData -> error", error)
                        }
                        setTimeout(() => {
                            this.isLoadingList = false;
                        }, 500);
                    })
                    .catch(error => {                        
                        console.error("getData -> error", error);
                        setTimeout(() => {
                            this.isLoadingList = false;
                        }, 500);
                    });
            },
            refresh() {
                this.pagination.pageIndex = 1;
                this.getData(this.pagination);
            },
            closeUserRequestModal() {
                this.$emit("closeUserRequestModal");
            }
        }
    }
</script>

<style>
    .detail-list .el-card__body {
        padding: 0.5rem 0.8rem;
    }

    .ps.ps--active-y{
        padding-right: 0;
    }
</style>